import { graphql } from 'gatsby'
import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import useLocalisation from '../helper/useLocalisation'
import {
  BodyText,
  ContentPadding,
  CreamBackground,
  Heading,
  ImageContainer,
  SplitContainer,
  SplitItem,
  StaticContainer,
  StyledImage,
  TopMagin,
} from '../styles/static.js'

const WhyPage = ({ data }) => {
  const stack = data.Comfort.childImageSharp.gatsbyImageData
  const Q = useLocalisation()
  return (
    <Layout>
      <SEO
        title={data.SEO.title.value}
        description={data.SEO.description.value}
        cannonical_url={data.SEO.cannonical_url.value}
        og_image={
          data.SEO?.TEST_banner_image?.value?.childImageSharp?.gatsbyImageData
            ?.srcWebp
        }
      />
      <StaticContainer>
        <TopMagin />
        <span style={{ display: 'none' }}> Test Span </span>
        <CreamBackground>
          <ContentPadding>
            <SplitContainer center={true}>
              <SplitItem>
                <ImageContainer>
                  <StyledImage image={stack} />
                </ImageContainer>
              </SplitItem>
              <SplitItem>
                <Heading main={true}> {Q('store-heading')} </Heading>
                <br />
                <BodyText>{Q('store-body', {}, true)}</BodyText>
              </SplitItem>
            </SplitContainer>
          </ContentPadding>
        </CreamBackground>
      </StaticContainer>
    </Layout>
  )
}

export default WhyPage

export const query = graphql`
  {
    Comfort: file(relativePath: { eq: "store_02-small.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }

    SEO: cockpitPage(cannonical_url: { value: { eq: "/store" } }) {
      title {
        value
      }
      description {
        value
      }
      cannonical_url {
        value
      }
      keywords {
        value
      }
      category {
        value
      }
      TEST_banner_image {
        value {
          childImageSharp {
            gatsbyImageData(width: 1200, height: 630, layout: FIXED)
          }
        }
      }
    }
  }
`
